<template>
  <v-container>
    <base-material-card
      icon="mdi-domain"
      inline
      v-if="!pageLoading"
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{
            `${
              edit
                ? `${$t("edit")} ${managerCompanyData.name}`
                : `${$t("add")} ${$t("manager_company")}`
            } `
          }}</b>
        </h1></template
      >

      <!-- FORM TO EDIT OR ADD MANAGER COMPANY -->
      <v-card-text>
        <v-form ref="form" @submit.stop.prevent="checkForm()">
          <v-text-field
            v-model="managerCompanyData.name"
            :label="$t('name')"
            :rules="[required]"
          />

          <div class="cropper-and-logo-container">
            <!-- logo -->
            <div class="mt-5">
              <p class="colorpickerTitle">{{ $t("logo") }}</p>
              <input
                class="ml-0"
                id="imageLogoInput"
                name="imageLogoInput"
                ref="imageLogoInput"
                type="file"
                @change="setImageLogo"
              />
              <p class="alert-size-image-message mt-1">
                {{ $t("alert_size_image") }}
              </p>
            </div>

            <!-- cropper -->
            <div class="cropper-container">
              <v-checkbox v-model="isCropSelected" hide-details="" /><span>{{
                $t("crop")
              }}</span>
              <v-tooltip left max-width="300px" style="align-items: end">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    elevation="0"
                    icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-help-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("crop_description") }}</span>
              </v-tooltip>
            </div>
          </div>

          <!-- cropped preview image -->
          <div v-if="isCropSelected == true && imagePath">
            <cropper
              @change="change"
              style="max-width: 483px; height: 210px"
              :src="imagePath"
              class="cropper"
            >
            </cropper>
          </div>

          <!-- image after saved -->
          <div v-if="imagePath && !isCropSelected">
            <img id="selected-company-logo" :src="imagePath" />
          </div>

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              :disabled="!imageSizeIsValid"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="secondary"
              min-width="100"
              @click="goBack"
            >
              {{ $t("go_back") }}
            </v-btn>
          </v-card-actions>

          <v-alert class="ma-2" v-if="savedContent" type="success">
            <span>{{ $t("saved") }}</span>
          </v-alert>
        </v-form>
      </v-card-text>
    </base-material-card>
    <div v-else class="box-loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ManagerPartner",
  components: {
    Cropper,
  },
  data() {
    return {
      edit: false,
      apiService: new ApiService(),
      managerCompanyData: {
        name: null,
        fileString: null,
        fileExtension: null,
      },
      imageSizeIsValid: true,
      loading: false,
      savedContent: false,
      managerPartnerId: null,
      imagePath: null,
      isCropSelected: false,
      imageToCropCanvas: null,
      pageLoading: false,
    };
  },
  async created() {
    const editManager = this.$route.query.id;

    if (editManager) {
      this.edit = true;
      this.managerPartnerId = editManager;
      await this.getManagerCompanyToEdit();
    }
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    change({ coordinates, image, canvas }) {
      if (canvas != null || canvas != undefined)
        this.imageToCropCanvas = canvas.toDataURL();
      this.result = {
        coordinates,
        image,
      };
      this.cropImage();
    },
    setImageLogo(event) {
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.imagePath = reader.result;
        this.managerCompanyData.fileString = reader.result;
        this.managerCompanyData.fileExtension = reader.result
          .split("/")[1]
          .split(";")[0];
      };

      this.validatingImageSize(event)
        .then(() => {
          this.imageSizeIsValid = true;
          this.$forceUpdate();
        })
        .catch(() => {
          this.imageSizeIsValid = false;
          this.$toast.error(this.$t("alert_error_size_image"));
        });
    },
    async validatingImageSize(event) {
      this.imageSizeIsValid = true;

      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);

        reader.onload = function (e) {
          let image = new Image();

          image.src = e.target.result;
          image.onload = function () {
            let height = this.height;
            let width = this.width;

            if (height === 50) {
              resolve();
            } else {
              reject();
            }
          };
        };
      });
    },
    async getManagerCompanyToEdit() {
      if (this.managerPartnerId) {
        this.pageLoading = true;
        await this.apiService
          .getRequest(`managerpartner/${this.managerPartnerId}`)
          .then((resp) => {
            this.managerCompanyData = resp.content;
            this.imagePath = resp.content.logoPath;
          })
          .catch((err) => {
            this.$toast.error(this.$t("an_error_ocurred"));
          });
        this.pageLoading = false;
      }
    },
    goBack() {
      this.$router.back();
    },
    resetFormValues() {
      Object.keys(this.managerCompanyData).forEach((key) => {
        this.managerCompanyData[key] = "";
      });
    },
    async submit() {
      this.savedContent = false;
      this.loading = true;
      let payload = this.managerCompanyData;

      if (payload.logoPath) delete payload.logoPath;

      if (this.isCropSelected) {
        payload.fileString = this.imageToCropCanvas;
      }

      if (this.edit) {
        await this.apiService
          .putRequest(`managerpartner`, payload)
          .then((resp) => {
            this.savedContent = true;
          })
          .catch((err) => {
            this.$toast.error(this.$t("an_error_ocurred"));
          });
      } else {
        await this.apiService
          .postRequest(`managerpartner`, payload)
          .then(async (resp) => {
            this.resetFormValues();
            this.$router.push("/manager");
            this.$toast.success(this.$t("saved"));
          })
          .catch((err) => {
            this.$toast.error(this.$t("an_error_ocurred"));
          });
      }
      this.loading = false;
    },
    async checkForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        await this.submit();
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#selected-company-logo {
  width: auto;
  height: auto;
  max-width: 460px;
  max-height: 260px;
}

.cropper-and-logo-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.cropper-container {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.alert-size-image-message {
  font-size: 14px;
  font-weight: 400;
  font-family: "Source Sans Pro";
  color: var(--dark);
}

.colorpickerTitle {
  margin-bottom: 20px;
  font-weight: 500;
  color: var(--dark) !important;
}

.box-loading {
  display: grid;
  place-items: center;
}
</style>
